import { LockKeyhole, ArrowLeft, Key } from 'lucide-react'
import { Link } from 'react-router'
import { Button } from '#app/components/ui/button'
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from '#app/components/ui/card'

const taglines = [
	'Access denied! Your credentials must have taken a coffee break.',
	'Oops! Looks like you forgot the secret handshake.',
	'Unauthorized? Maybe try whispering the password to the screen.',
	'You need clearance for this mission... and you don’t have it.',
	'This page is members-only, and your name’s not on the list.',
	'Your access card just got declined—try again!',
	'No entry! Even our virtual bouncers say no.',
	'Oops! Looks like you missed the security briefing.',
	'This page requires a special key… and you’re holding a paperclip.',
	'Uh-oh, your login didn’t pass our firewall’s vibe check.',
	'Access denied! Maybe try knocking next time?',
	'You need higher clearance to unlock this digital vault.',
	'Looks like your password is stuck in traffic—try again later!',
	'Unauthorized? Either you forgot your password, or you’re a hacker.',
	'This page is locked tighter than Fort Knox.',
	"We’d love to let you in, but then we'd have to reset the internet.",
	'Hold up! Your security badge is expired.',
	'Only authorized personnel beyond this point—nice try!',
	'Your access attempt has been recorded… for training purposes.',
	'Sorry, but this page is on a need-to-know basis.',
	'Nope! Your credentials are about as valid as expired milk.',
	'You almost made it! Just kidding, still unauthorized.',
	'This page is behind a velvet rope, and you forgot your VIP pass.',
	"Our security AI says 'Nope, not today!'",
	'Access denied—maybe try hacking the mainframe in a movie montage?',
	"Looks like you're trying to sneak in without a VIP pass!",
]

const tagline = taglines[Math.floor(Math.random() * taglines.length)]

export default () => {
	return (
		<div className="flex items-center justify-center bg-gradient-to-br from-background to-muted p-4">
			<Card className="w-full max-w-md border-destructive/20">
				<CardHeader className="space-y-1 text-center">
					<div className="mb-4 flex justify-center">
						<div className="relative">
							<div className="absolute inset-0 animate-ping rounded-full bg-destructive/20 opacity-75" />
							<div className="relative rounded-full bg-background p-4 shadow-lg">
								<LockKeyhole className="h-12 w-12 text-destructive" />
							</div>
						</div>
					</div>
					<CardTitle className="text-3xl font-bold tracking-tight">
						401 - Access Denied
					</CardTitle>
					<CardDescription className="text-lg">{tagline}</CardDescription>
				</CardHeader>
				<CardContent className="space-y-4">
					<div className="flex items-center justify-center space-x-2 py-4">
						<div className="h-px flex-1 bg-border"></div>
						<Key className="h-4 w-4 text-muted-foreground" />
						<div className="h-px flex-1 bg-border"></div>
					</div>

					<p className="text-center text-muted-foreground">
						You need proper authentication to access this area. Please log in or
						contact an administrator if you believe this is a mistake.
					</p>
				</CardContent>
				<CardFooter className="flex flex-col space-y-2">
					<Button asChild className="w-full">
						<Link to="/login">Log In</Link>
					</Button>
					<Button asChild variant="outline" className="w-full">
						<Link to="/" className="flex items-center justify-center">
							<ArrowLeft className="mr-2 h-4 w-4" />
							Back to Home
						</Link>
					</Button>
				</CardFooter>
			</Card>
		</div>
	)
}
