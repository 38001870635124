import { AlertTriangle, Home, RefreshCw } from 'lucide-react'
import { Link } from 'react-router'
import { twMerge } from 'tailwind-merge'
import { Button } from '#app/components/ui/button'
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from '#app/components/ui/card'

const taglines = [
	'Looks like our servers are taking an unexpected coffee break.',
	"Our hamsters powering the server got tired. We're getting new ones.",
	'The server is experiencing an existential crisis.',
	'Error 500: Server decided to ghost us.',
	'Our server is currently meditating. Please try again later.',
	'The server went to find itself. It should be back soon.',
	'Oops! Our code monkeys are working overtime to fix this.',
	"Server malfunction: It's not you, it's definitely us.",
	'The server is having a bad day. We all have those sometimes.',
	'Our servers are experiencing technical difficulties. Human difficulties to follow.',
]

const tagline = taglines[Math.floor(Math.random() * taglines.length)]

export default ({ error }: any) => {
	return (
		<div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-background to-muted p-4">
			<Card className="w-full max-w-md shadow-lg">
				<CardHeader className="flex flex-col items-center space-y-2 pb-2">
					<div className="relative mb-2 h-24 w-24">
						<div className="absolute inset-0 flex items-center justify-center">
							<AlertTriangle className="h-12 w-12 animate-pulse text-destructive" />
						</div>
						<div
							className={twMerge(
								`absolute inset-0 rounded-full border-4 border-destructive opacity-20`,
								`motion-safe:animate-spin-slow motion-safe:border-2 motion-safe:border-r-4`,
							)}
						></div>
					</div>
					<h1 className="text-4xl font-bold tracking-tight">500</h1>
					<p className="text-center text-xl font-semibold">Server Error</p>
				</CardHeader>

				<CardContent className="px-6 text-center">
					<p className="mb-4 italic text-muted-foreground">{tagline}</p>
					<p className="text-sm">
						Something went wrong on our servers. We've been notified and are
						working to fix the issue.
					</p>
					{error && (
						<div className="mt-4 max-h-32 overflow-auto rounded-md bg-muted p-3 text-left text-xs">
							<code>{JSON.stringify(error, null, 2)}</code>
						</div>
					)}
				</CardContent>

				<CardFooter className="flex justify-center gap-4 pt-2">
					<Button
						variant="outline"
						onClick={() => window.location.reload()}
						className="flex items-center gap-2"
					>
						<RefreshCw className="h-4 w-4" />
						Try Again
					</Button>
					<Button asChild className="flex items-center gap-2">
						<Link to="/">
							<Home className="h-4 w-4" />
							Go Home
						</Link>
					</Button>
				</CardFooter>
			</Card>
		</div>
	)
}
